import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import { FaTimes } from "react-icons/fa";
import CheckIcon from '../../images/icons/icon-check.svg';
import { createSlugFromAddress } from '../../utils';
import {closePopUp} from '../../state/app';

import "./style.scss";

const MapPopUp = ({currentSpot, materials, language, dispatch}) => {

  const randomNumberGen = () => {
    return Math.round(Math.random() * 10000);
  }

  const returnAvailableMaterials = (statemats, spotmats) => {
    if(spotmats) {
    const spotMatCodes = spotmats.map(material => (material.code))

    const list = statemats.map(statemat => {
 
      if(statemat.node.language === language && spotMatCodes.includes(statemat.node.code)) {
        return (<li key={`${randomNumberGen()}-key`}><CheckIcon />{statemat.node.name}</li>);
      }
    })
    return list;
  }
  }
  
  return (
    <div className="mapPopUp">
      <div className="popUpInner">
        <button className="closePopUpBtn" onClick={() => dispatch(closePopUp())}>
              <span>
              {language === 'fi' ? 'Sulje' : null}
              {language === 'sv' ? 'Stäng' : null}
              {language === 'en' ? 'Close' : null}
              </span>
              <FaTimes />
        </button>
        <h3>{currentSpot.name}</h3>
        <p>{currentSpot.operator}</p>
        <p>{currentSpot.address}<br />
        {currentSpot.postal_code} {currentSpot.municipality}</p>
        <ul>
          {returnAvailableMaterials(materials, currentSpot.materials)}
        </ul>
        <div className="popUpControls">
          <div className="popUpControlsInner">
            <Link className="infoPopUpBtn" to={`/${createSlugFromAddress(currentSpot)}`}>
            {language === 'fi' ? 'Kierrätyspisteen lisätiedot' : null}
              {language === 'sv' ? 'Mera info' : null}
              {language === 'en' ? 'More information' : null}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  currentSpot: state.app.currentSpot,
  materials: state.app.materials,
  language: state.language.language
}), null)(MapPopUp);