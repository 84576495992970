import React from "react";
import { connect } from "react-redux";
import InfoIcon from '../../images/icons/icon-info.svg';
import { Link } from 'gatsby';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import "./style.scss";

import { filterSpots, initSpots, openFilters, closeFilters } from "../../state/app";


const Filters = ({dispatch, allSpots, activeFilter, language, materials, filtersOpen}) => {

  const handleMobileFiltersToggle = () => {
    if(filtersOpen === true) {
      dispatch(closeFilters());
    } else {
      dispatch(openFilters());
    }
  }

  const randomNumberGen = () => {
    return Math.round(Math.random() * 10000);
  }

  return (

      
      <div className={`mapFilters ${filtersOpen ? 'expandedMobileFilters':null}`}>

         <h2 onClick={handleMobileFiltersToggle}>
          {language === 'fi' ? 'Mitä haluat kierrättää?': null}
          {language === 'sv' ? 'Vad vill du återvinna?': null}
          {language === 'en' ? 'What you want to recycle?': null}
          {filtersOpen ? <FaAngleDown className="mobileFilterToggle" /> : <FaAngleUp className="mobileFilterToggle" onClick={handleMobileFiltersToggle} />}
         </h2> 
         <ul className={`filterList ${!filtersOpen ? 'hideFilterList':null}`}>
         <li 
           onClick={e => dispatch(initSpots(allSpots))} 
           className={activeFilter.length < 1 || activeFilter.includes("Kaikki") ? 'activeFilter':null}>
             <input id="kaikki" type="checkbox" value="Kaikki" checked={activeFilter.length < 1 || activeFilter.includes("Kaikki")} onChange={e => dispatch(initSpots(allSpots))}  />
             <label htmlFor="kaikki">{language === 'fi' ? 'Kaikki' :null}{language === 'sv' ? 'Alla' :null}{language === 'en' ? 'All' :null}</label>
          </li>
          {materials ? materials.map(material => {
            if(material.node.language === language) {
              return (
                 <li className="filterRow" key={randomNumberGen()}>
                  <div onClick={e => dispatch(filterSpots(material.node.code , allSpots, activeFilter))} className={activeFilter.includes(material.node.code) ? 'activeFilter':null}>
                     <input type="checkbox" id={material.node.name} value={material.node.name} checked={activeFilter.includes(material.node.code)} onChange={e => dispatch(filterSpots(material.node.code , allSpots, activeFilter))}/> 
                      <label htmlFor={material.node.name}>{material.node.name}</label>
                  </div>
                  <Link className="infoIcon" to={`/${material.node.slug}`}> <InfoIcon /><span className="hidden">{material.node.name}</span></Link>
               </li>
              )
            } else return null;
            
          }):null }

          </ul>
          
      </div>


  );
};


export default connect(state => ({
  allSpots: state.app.allSpots,
  activeFilter: state.app.activeFilter,
  counts: state.app.counts,
  language: state.language.language,
  materials: state.app.materials,
  filtersOpen: state.app.filtersOpen
}), null)(Filters);