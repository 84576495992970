import React from "react"
import { connect } from "react-redux"

import "./style.scss"
import "react-leaflet-markercluster/dist/styles.min.css"
import Map from "../map"
import Filters from "../filters"

const MapWrapper = ({ data, filteredSpots, filtersOpen, embed }) => {
  return (
    <div className="frcMapContainer">
      {!embed && <Filters />}

      {typeof window !== "undefined" ? (
        <div>
          <Map
            items={data}
            filteredSpots={filteredSpots}
            embed={embed | false}
          />
        </div>
      ) : null}
    </div>
  )
}

export default connect(
  state => ({
    filteredSpots: state.app.filteredSpots,
    filtersOpen: state.app.filtersOpen,
  }),
  null
)(MapWrapper)
