import React from "react"
import { connect } from "react-redux"
import L from "leaflet"
import MarkerClusterGroup from "leaflet.markercluster"
import GeoSearch from "../geosearch"
import LocateUser from "../locateUser"
import "./style.scss"
// Redux actions
import {
  initSpots,
  openPopUp,
  closePopUp,
  setMapCenter,
  resetSearch,
  initSpotsFromUrl,
} from "../../state/app"

class Map extends React.Component {
  componentDidMount() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const queryLat = urlParams.get("lat")
    const queryLng = urlParams.get("lng")
    const queryZoom = urlParams.get("zoom")
    const queryFilters = urlParams.get("filters")

    // Close popup if enter of esc key is press
    this.ESCAPE_KEY = 27
    this.ENTER_KEY = 13
    this.handleKeyDown = event => {
      switch (event.keyCode) {
        case this.ESCAPE_KEY:
          this.props.closePopUp()
          break
        case this.ENTER_KEY:
          this.props.closePopUp()
          break
        default:
          break
      }
    }
    document.addEventListener("keydown", this.handleKeyDown)

    // Init spots to Redux

    if (queryFilters) {
      const filterArr = queryFilters.split(",")
      const filterNumArr = filterArr.map(filterStr => {
        return parseInt(filterStr)
      })
      this.props.initURLSpots(filterNumArr, this.props.items)
    } else {
      this.props.initSpots(this.props.items)
    }
    // Close popup in state when map component is mounted
    this.props.closePopUp()

    // custom icon
    this.recycleIcon = L.icon({
      iconUrl: "./marker.png",
      iconAnchor: [14, 40],
    })

    // create map
    this.map = L.map("map", {
      center:
        queryLat && queryLng ? [queryLat, queryLng] : this.props.currentCenter,
      zoom: queryZoom ? queryZoom : this.props.currentZoom,
      maxZoom: 16,
      preferCanvas: true,
      zoomControl: false,
      layers: [
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    })
    // Cluster markers
    this.markers = L.markerClusterGroup({ disableClusteringAtZoom: 14 })
    // Add markers
    this.props.items.forEach(item => {
      {
        if (item.node.geometry !== null) {
          this.markers.addLayer(
            L.marker(
              [
                item.node.geometry.coordinates[1],
                item.node.geometry.coordinates[0],
              ],
              { icon: this.recycleIcon }
            ).on("click", () => this.props.openPopUp(item.node))
          )
        }
      }
    })
    this.map.addLayer(this.markers)

    // Zoom and locate controls
    L.control.zoom({ position: "bottomright" }).addTo(this.map)

    this.checkForCenterPos = setInterval(() => {
      this.props.setMapCenter(this.map.getCenter(), this.map.getZoom())
    }, 4000)
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentCenter !== prevProps.currentCenter) {
      // Change mapcenter and zoom when search is done and only when search is done
      if (this.props.wasSearch) {
        if (this.searchMarker != undefined) {
          this.map.removeLayer(this.searchMarker)
        }
        this.map.flyTo(
          new L.LatLng(
            this.props.currentCenter[0],
            this.props.currentCenter[1]
          ),
          this.props.currentZoom
        )
        this.searchMarker = L.marker([
          this.props.currentCenter[0],
          this.props.currentCenter[1],
        ]).addTo(this.map)
        this.props.resetSearch()
      }
    }

    if (this.props.popUpOpen !== prevProps.popUpOpen) {
      this.props.setMapCenter(this.map.getCenter(), this.map.getZoom())
    }

    if (this.props.filteredSpots !== prevProps.filteredSpots) {
      // Use spots in Redux
      this.recycleIcon = L.icon({
        iconUrl: "/marker.png",
        iconAnchor: [14, 40],
      })

      this.map.removeLayer(this.markers)
      this.markers = L.markerClusterGroup({
        disableClusteringAtZoom: 13,
        spiderfyOnMaxZoom: false,
      })

      this.props.filteredSpots.forEach(item => {
        {
          if (item.node.geometry !== null) {
            this.markers.addLayer(
              L.marker(
                [
                  item.node.geometry.coordinates[1],
                  item.node.geometry.coordinates[0],
                ],
                { icon: this.recycleIcon }
              ).on("click", () => this.props.openPopUp(item.node))
            )
          }
        }
      })
      this.map.addLayer(this.markers)
    }
    // Change control labels etc if language is changed
    if (this.props.language !== prevProps.language) {
      this.returnSearchPlaceholder = lang => {
        if (lang === "fi") {
          return "Hae osoitteella"
        } else if (lang === "sv") {
          return "Sök med adress..."
        } else {
          return "Search with address..."
        }
      }
    }
  }
  componentWillUnmount() {
    // remove event listener for keypdown
    document.removeEventListener("keydown", this.handleKeyDown)
    clearInterval(this.checkForCenterPos)
  }

  render() {
    return (
      <div>
        {!this.props.embed && <GeoSearch />}

        <div className="mapContainerElement" id="map">
          <LocateUser />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  openPopUp: spot => dispatch(openPopUp(spot)),
  closePopUp: () => dispatch(closePopUp()),
  initSpots: spots => dispatch(initSpots(spots)),
  setMapCenter: (center, zoom) => dispatch(setMapCenter(center, zoom)),
  resetSearch: () => dispatch(resetSearch()),
  initURLSpots: (filters, spots) => dispatch(initSpotsFromUrl(filters, spots)),
})

export default connect(
  state => ({
    language: state.language.language,
    currentCenter: state.app.currentCenter,
    currentZoom: state.app.currentZoom,
    popUpOpen: state.app.popUpOpen,
    wasSearch: state.app.wasSearch,
  }),
  mapDispatchToProps
)(Map)
