import React, { Component } from "react";
import { connect } from "react-redux";
import { setMapCenter } from '../../state/app';
import "./style.scss";

const LocateUser = ({language, setMapCenter}) => {
  // get user location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const center = {lat: pos.coords.latitude, lng: pos.coords.longitude}
        setMapCenter(center, 15, true);
      });
    } else {
      console.log('geolocation not supported')
    }
  }

  return (
    <div className="geoLocation">
      <img alt="" className="geoLocateIcon" src='./locate.png' onClick={getLocation} />
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  setMapCenter: (center, zoom, search) => dispatch(setMapCenter(center, zoom, search))
});

export default connect(state => ({
   language: state.language.language,
 }), mapDispatchToProps)(LocateUser);