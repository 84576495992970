import React, { Component} from "react";
import { connect } from "react-redux";
import { FaTimes, FaSearch } from "react-icons/fa";
import { setMapCenter } from '../../state/app';
import "./style.scss";

class GeoSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      showSuggest: false,
      searchSuggestions: [],
      lastKeyPress: 0,
      typingTimeout: 0
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.addResultsFromDatabase = this.addResultsFromDatabase.bind(this);
    this.removeDuplicatesAndSetResults = this.removeDuplicatesAndSetResults.bind(this);
    this.runAutoCompleteSearchQuery = this.runAutoCompleteSearchQuery.bind(this);
    this.runCompleteSearchQuery = this.runCompleteSearchQuery.bind(this);
    this.chooseSuggestion = this.chooseSuggestion.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.getPlaceholderText = this.getPlaceholderText.bind(this);
  }

  addResultsFromDatabase() {
    return true;
  }

  removeDuplicatesAndSetResults(features) {
    if(features[0]) {
          
      // filter duplicate results of same city away
      let filteredResults = [];
      features.forEach(feature => {
        const city = feature.properties.localadmin;

        const isIn = filteredResults.some( item => 
          {
            if(city && item.properties.localadmin === city){
              return true;

            }  else {return false}
          
          
          } )
        if(!isIn) {
          filteredResults.push(feature);
        }
      });
      this.setState({searchSuggestions: filteredResults, showSuggest: true});
    } else {
      // if no result set no result
      this.setState({searchSuggestions: [], showSuggest: false});
    }
  }


  async runCompleteSearchQuery() {
    // this does also fuzzy matching
    if (this.state.searchInput !== "") {
  
      fetch(`https://api.digitransit.fi/geocoding/v1/search?text=${this.state.searchInput}&lang=${this.props.language}&focus.point.lat=${this.props.currentCenter[0]}&focus.point.lon=${this.props.currentCenter[1]}&digitransit-subscription-key=${process.env.GATSBY_DIGITRANSIT_SUBSCRIPTION_KEY}`)
      .then((response) => {
        return response.json();
      }).then((results)=>{    
        let features = results.features;  
        this.removeDuplicatesAndSetResults(features);  
      });

    }
  }


  async runAutoCompleteSearchQuery() {

    if (this.state.searchInput !== "") {
     
      fetch(`https://api.digitransit.fi/geocoding/v1/autocomplete?text=${this.state.searchInput}&lang=${this.props.language}&focus.point.lat=${this.props.currentCenter[0]}&focus.point.lon=${this.props.currentCenter[1]}&digitransit-subscription-key=${process.env.GATSBY_DIGITRANSIT_SUBSCRIPTION_KEY}`)
      .then((response) => {
        return response.json();
      }).then((results)=>{      
        let features = results.features;            
        this.removeDuplicatesAndSetResults(features);   

      });
 
      
    } else {
      this.setState({searchSuggestions: [], showSuggest: false});
    }
  }// autocomplete search ends here

  chooseSuggestion(e, suggest) {
    const lon = suggest.geometry.coordinates[0];
    const lat = suggest.geometry.coordinates[1];
    const center = {lat: parseFloat(lat), lng: parseFloat(lon)}
    this.props.setMapCenter(center, 15, true);
    this.setState({showSuggest: false})
  }

  handleChange(e) {
    e.preventDefault();
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
   }
    this.setState({searchInput: e.target.value, typingTimeout: setTimeout(this.runCompleteSearchQuery, 1500)} )
    this.runAutoCompleteSearchQuery();  
       
  }

  handleKeyDown = (event) => {

    
    // handle enter
    const enter = 13
      switch( event.keyCode ) {
        case enter:
            this.runCompleteSearchQuery();
            break;
        default: 
            break;
      }
    }

     // get user location
    getLocation = () => {
      if (navigator.geolocation) {
      
        navigator.geolocation.getCurrentPosition((pos) => {
          
          const center = {lat: pos.coords.latitude, lng: pos.coords.longitude}
          this.props.setMapCenter(center, 15, true);
        });
      } else {
        console.log('geolocation not supported')
      }
    }

    getPlaceholderText = (lang) => {
      if(lang === 'fi') {
        return 'Kirjoita osoite tai salli paikannus';
      } else if (lang === 'sv') {
        return 'Skriv adress eller använd min position';
      } else {
        return 'Type address or locate';
      }
    }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    const timestamp = Date.now();
    this.setState({lastKeyPress: timestamp})
  }
  render() {
    return (
      <div className="frcGeoSearch">
        <img alt="" className="geoLocateInsideSearchField" src='./locate.png' onClick={this.getLocation} />
        <input 
        type="search" 
        autoComplete="off"
        value={this.state.searchInput} 
        id="search"
        onChange={this.handleChange}
        placeholder={this.getPlaceholderText(this.props.language)}
        />
        <label className="hidden" htmlFor="search">{this.getPlaceholderText(this.props.language)}</label>
        {this.state.searchInput !== "" ? 
        <FaTimes className="closeSearchBtn" onClick={
          () => {this.setState({searchInput: "", showSuggest: false})}
        } /> 
       
        :null}
         <FaSearch className="searchSubmitBtn" onClick={this.runAutoCompleteSearchQuery} />
        {this.state.showSuggest ?
        <div className="searchSuggestions">
          <ul>
            {this.state.searchSuggestions !== [] ?
              this.state.searchSuggestions.map(suggest => {
              
                return (
                <li key={suggest.properties.id} onClick={(e)=> this.chooseSuggestion(e, suggest)}>
                 <span>{suggest.properties.label} </span>
              
                </li>
                )
              })
            : null}
          </ul>
          <div className="closeSearchSuggestionsBtn" onClick={() => this.setState({showSuggest: false})}>
            {this.props.language === 'fi' ? 'Sulje' : null}
            {this.props.language === 'sv' ? 'Stäng' : null}
            {this.props.language === 'en' ? 'Close' : null}
            <FaTimes />

          </div>
        </div>:null}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setMapCenter: (center, zoom, search) => dispatch(setMapCenter(center, zoom, search))
});

export default connect(state => ({
   language: state.language.language,
   currentCenter: state.app.currentCenter
 }), mapDispatchToProps)(GeoSearch);